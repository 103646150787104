@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
*{
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}
.logo{
  display: flex;
  justify-content: center;
  margin-inline: auto;
  width: 30vh;
}
.spline{
  margin: 25vh 0 0 -20vh;
  height: 100vh;
  z-index: 0;
}
.login-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
  height: 100vh;
  background: linear-gradient(
      to right,
      #3A54AA 0%,
      #3A54AA 50%,
      white 50%,
      white 100%
    );
  /* overflow-y: hidden; */
}
.loginvectordiv{
  display: flex;
  justify-content: center;
  align-items: center;
}
.loginvector{
  width: 60vh; 
}
.loginvector3{
  width: 60vh; 
}
.error{
  color: #c62828;
  text-align: center;
}
.message{
  color:#43a047;
  text-align: center;
}
.input{
  margin: 1vh;
  display: flex;
  flex-direction: column;
}
.input input{
  border-radius: 3px;
  padding: 5px;
  border: 1px solid grey;
  max-width: 50vh;
  height: 4vh;
}
.input label{
  font-weight: 600;
}
.Login-form{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  margin-right: 2vh;
  width: min(90%, 50vh);
}
.Login-Title{
  text-align: center;
  margin: 1vh;
}
.Signup-button{
  color: white;
  background-color: #3A54AA;
  border: none;
  box-shadow: 0px 2px 4px grey;
  border-radius: 3px;
  width: 50vh;
  height: 5vh;
  margin: 1vh;
  cursor: pointer;
}
.Google-button{
  color: black;
  background-color: white;
  border: none;
  box-shadow: 0px 2px 4px grey;
  border-radius: 3px;
  width: 50vh;
  height: 5vh;
  margin: 1vh;
  cursor: pointer;
}
.google{
  width: 4vh;
  margin: 2px;
}
.flex{
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 1150px) {
  .login-container{
    background: white;  
  }
  .loginvectordiv{
    display: none;
  }
}

/* -----------------CSS for the Home Page---------------- */

.Box{
  margin-top: 10vh;
  margin-bottom: 10vh;
}
.Box h1{
  text-align: center;
  color: #3A54AA;
  font-size: 7vh;
}
.Box img{
  display: flex;
  justify-content: center;
  margin-inline: auto;
  margin-top: 5vh;
  width: min(90%,70vh);
}
.Box1{
  display: flex;
  flex-wrap: wrap;
  width: 150vh;
  margin-top: 10vh;
  margin-bottom: 10vh;
  margin-inline: auto;
  justify-content: space-around;
}
.Box1 h2{
  font-size: 5vh;
  font-weight: light;
  margin: 0;
  padding: 0;
}
.Box1 p{
  font-size: 3vh;
  width: 60vh;
}


.Box1 img{

  width: 60vh;
}
.Box2{
  display: flex;
  flex-wrap: wrap;
  margin-top: 20vh;
  margin-bottom: 10vh;
  justify-content: space-evenly;
  width: 150vh;
  margin-inline: auto;
}
.Box2 h2{
  font-size: 5vh;
  font-weight: light;
  margin: 0;
  padding: 0;
}
.Box2 p{
  font-size: 3vh;
  width: 50vh;
}
.Box2 img{
  width: 40vh ;
}
.Content{
  margin-right: 30vh;
}
@media only screen and (max-width: 600px){
  .Box1{
    width: min(90%,60vh);
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-inline: auto;
  }
  .Box1 img{
    width: min(80%,60vh);
  }
  .Box2 img{
    width: min(60%, 60vh);
  }
  .Box2{
    width: min(90%, 60vh);
      display: flex;
      flex-direction: column;
      text-align: center;
      margin-inline: auto;
  }
  .Box2 p{
    font-size: 2vh;
    width: 40vh;
  }
  .Box1 h2{
    font-size: 4vh;
  }
  .Box2 h2{
    font-size: 4vh;
  }
  .Box1 p{
    font-size: 2vh;
    width: 40vh;
  }
  .Content{
    margin-right: 0vh;
    margin-inline: auto;
  }
}
.songdiv{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
}
.text-link {
  color: inherit;
  text-decoration: inherit;
}
.songcard{
  text-align: center;
  background-color: #3A54AA;
  width: 30vh;
  height: 40vh;
  border-radius: 5px;
  box-shadow: 0 0 4px grey;
  color: white;
  margin: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  transition: 0.2s ease-in-out ;
}
.songcard:hover{
  transform: scale(1.1);
}

.animate :hover{
  background-color: #183eb9;
  transform: 0.5s ease;
}
.songcard img{
  width: 25vh;
  margin-top: 2vh;
}

.Box3 h2{
  margin-top: 10vh;
  margin-bottom: 10vh;
  text-align: center;
  font-size: 4vh;
  font-weight: light;
}
.explorebutton{
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10vh;
  margin-bottom: 10vh;
  width: 20vh;
  color: white;
  background-color: #3A54AA;
  border-radius: 20px;
  box-shadow: 0 0 4px grey;
  text-decoration: none;
  border: none;
  cursor: pointer;
}

.linkbutton{
  text-decoration: none;
  color: white;
  padding: 1vh;
  cursor: pointer;
}

/* ------------------Contribute Page-------------------- */

.Form h1{
  text-align: center;
  color: #3A54AA;
  margin-top: 5vh;
}
.Form-div{
  margin: 5vh;
  width: 50vh;
  display: flex;
  flex-direction: column;
  margin-inline: auto;
}
.Input-text-area{
  margin: 2vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.Form-div input{
  padding-left: 0.3vh;
  border-radius: 5px;
  border: 2px solid #3A54AA;
  height: 5vh;
}
.Form-div textarea{
  border-radius: 5px;
  border: 2px solid #3A54AA;
  
}
.Songtextarea{
  margin: 2vh;
}
.Abouttextarea{
  margin: 2vh;
}
.contributebutton{
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  /* margin-top: 2vh; */
  margin-bottom: 5vh;
  width: 30vh;
  height: 5vh;
  color: white;
  background-color: #3A54AA;
  border-radius: 20px;
  box-shadow: 0 0 4px grey;
  text-decoration: none;
  border: none;
  text-align: center;
  align-items: center;
  cursor: pointer;
}
.contributeimagebutton{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline: auto;
  margin-bottom: 5vh;
  margin-top: 1vh;
  padding-left: 5vh;
}
.contributeimagebutton::file-selector-button{
  background-color: #3A54AA;
  border: none;
  width: 17vh;
  height: 4vh;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}
.contributeflex{
  display: flex;
  justify-content: center;
}
.ContributeTextArea{
  margin-left: auto;
  margin-left: auto;
  width: 45vh;
}
.Form label{
  margin-bottom: 1vh;
}
@media only screen and (max-width: 850px){
  .contributeflex{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

}
/* --------------Explore page------------------ */
.Exploretitle{
  color: #3A54AA;
  text-align: center;
  margin-top: 10vh;
  margin-bottom: 5vh;
}
.Search-button-div{
  margin-top: 5vh;
  margin-bottom: 5vh;
  margin-inline: auto;
  width: 40vh;
}
.Search-button{
  width: 40vh;
  border-radius: 7px;
  border:2px solid #3A54AA;
  padding: 1vh;
}

/* -----------------------------------Song page-------------------- */
.Song-container{  
  margin-inline: auto;
}

.Song-name-div{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 10vh;
}
.Song-name-div h1{
  font-size: 7vh;
  color: #3A54AA;
}
.Song-name-div h3{
  font-size: 4vh
}
.Song-name-div img{
  width: 25vh;
}
.Song-name-div article{
  margin-right: 10vh;
}
.Song-lyrics-div{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 10vh;
  margin-bottom: 10vh;
  line-height: 5vh;
}
.Song-lyrics-div p{
  width: 50vh;
}
.AboutSongBox{
  background-color: #f7f7f7;
  border: 1px solid #3A54AA;
  border-radius: 10px;
  padding: 5vh;
  width: 35vh;
}
.AboutSongBox p{
  width: 35vh;
}
.AboutSongBox h3{
  color: #3A54AA;
  text-align: center;
}
.improvementbutton{
  margin-top: 1vh;
  margin-bottom: 1vh;
  width: 25vh;
  height: 5vh;
  color: white;
  background-color: #3A54AA;
  border-radius: 20px;
  box-shadow: 0 0 4px grey;
  text-decoration: none;
  border: none;
  text-align: center;
  align-items: center;
  cursor: pointer;
}
@media only screen and (max-width:600px){
  .AboutSongBox{
    margin-top: 5vh;
  }
  .Song-lyrics-div p{
    width: 40vh;
    /* margin-inline: auto; */
  }
  .Song-name-div article{
    text-align: center;
    margin-inline: auto;
  }
  .Song-name-div h1{
    font-size: 5vh;
  }
}

.comments-container h6{
  font-size: 2.5vh;
  color: #3A54AA;
}
.comments-input{
  margin-left: auto;
  margin-right: auto;
}

/* ////////////////////////////////////reset page///////////////////////////////////////// */

.reset-container{
    display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      width: 100%;
      height: 100vh;
}

/* //////////////////////////////////////////////////////////Profile Page//////////////////////////////////////////////////// */

.profilepage{
  text-align: center;
  
}
.profile-detail-container{
  margin-top: 10vh;
  margin-bottom: 10vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-top: 1px solid #3A54AA;
  border-bottom: 1px solid #3A54AA;
}
.Avatar-div{
  margin: 10vh;
}

.profilepage h1 {
  color: #3A54AA;
  margin-top: 5vh;
  margin-bottom: 3vh;
}

.Song-container{
  margin-bottom: 10vh;
}



/* /////////////////////////////////////////////////////Terms and condition ////////////////////////////////////////////// */

.terms-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 7vh;
  width: min(90%,70%);
  margin-inline: auto;
}
.terms-container h1{
  color: #3A54AA;
  text-align: center;
  margin: 5vh;
}
.terms-container li{
  padding: 2vh;
}
.terms-container ul li:before {
  /* the custom styled bullets */
  background-color: #3a54aa;
  border-radius: 50%;
  content: "";
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 2px;
  height: 10px;
  width: 10px;
}


/* ////////////////////////////////////////////////About us///////////////////////////////////////////////////////////////////// */

.aboutus-container{
  width: min(90%,110vh);
  margin-inline: auto;
  /* padding: 10vh; */
}
  
.aboutus-container h1 {
    color: #3A54AA;
  	font-size: 2.5em;
  	margin-top: 1em;
  	margin-bottom: 0.5em;
  	text-align: center;
  	}
  
.aboutus-container li{
  padding: 2vh;
}
  
.backtohome a {
  color: #3a54aa;
  text-decoration: none;
  font-weight: bold;
  margin-top: 5vh;
  margin-bottom: 5vh;
  display: flex;
  justify-content: center;
}
  
.aboutus-container a:hover {
  text-decoration: underline;
}

.logo2img{
  width: 40vh;
  margin-top: 6vh;
  margin-bottom: 4vh;
  display: flex;
  justify-content: center;
  margin-inline: auto;
}

/* -------------------------------------------------------Google button-------------------------------------------- */
@media only screen and (max-width:600px){
  #signindiv{
    width: 30vh;
  margin-left: 4vh;
  }
}
/* ==========================================================Error Page------------------------------------------ */
.error-container{
  margin-top: 10vh;
  text-align: center;
  margin-inline: auto;
  width: 90%;
}
.error-container h1,h4{
  font-weight: 400;
  padding: 2vh;
}
.error404{
  color: #3A54AA;
  font-size: min(900%,30vh);
  font-weight: bolder;
  text-align: center;
}
/* ///////////////////////////////////////////////////////////Razorpay Page//////////////////////////////////////////////////// */

.Donate-container{
  margin: 10vh;
}

.Donate-container h2{
  text-align: center;
  color: #3A54AA;
}
.Donate-container input{
  width: 30vh;
}

.Amount-input{
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}