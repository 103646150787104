@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #fcfcfc;
}

.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.navbar {
  position: sticky;
  top: 0;
  z-index: 1;
  height: 60px;
  background-color: white;
}

.logonav{
  margin-right: min(30%,80vh);
}
.versely{
  width: 25vh;
}

.menu-icon {
  display: none;
}

.nav-elements {
  margin-left: min(0%,60vh);
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
  color: #2f234f;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #574c4c;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
}

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 600px) {
  .menu-icon {
    display: block;
    cursor: pointer;
    
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 60px;
    background-color: white;
    width: 0px;
    height: calc(100vh);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
}
/* -----------------------------------------Footes CSS----------------------------------------------- */
img {
  max-width: 100%;
  height: auto;
}

section {
  padding: 10px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.contact-area {
  border-bottom: 1px solid white;
  border-top: 1px solid white;
}

.contact-content p {
  font-size: 15px;
  margin: 30px 0 60px;
  position: relative;
}

.contact-content p::after {
  background: white;
  bottom: -30px;
  content: "";
  height: 1px;
  left: 50%;
  position: absolute;
  transform: translate(-50%);
  width: 80%;
}

.contact-content h6 {
  color: white;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 10px;
}

.contact-content span {
  color: white;
  margin: 0 10px;
}

.contact-social {
  margin-top: 15px;
}

.contact-social>ul {
  display: inline-flex;
}

.contact-social ul li a {
  color: white;
  display: inline-block;
  height: 40px;
  margin: 0 15px;
  padding-top: 7px;
  width: 50px;
}

.contact-content img {
  max-width: 210px;
}

.footer-container section,
footer {
  background: #3A54AA;
  color: white;
}

footer p {
  padding: 20px 0;
  text-align: center;
}

footer img {
  width: 44px;
}

/* ///////////////////////////////////////Form popup component/////////////////////////////////////////////////*/
.Modal{
  position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
}
.FormPopup-container{
  background-color: white;
  width: min(90%,70vh);
  border: 2px solid #3a54aa;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5vh;
}
.FormPopup-container h2{
  text-align: center;
  color: #3a54aa;
  margin-top: 2vh;
}

.Formtextareadiv{
  width: min(90%,50vh);
  height: min(90%,40vh);
  margin-inline: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.Formtextareadiv textarea{
  border-radius: 5px;
    border: 2px solid #3A54AA;
}
.Formpopup-container-input{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Formpopup-container-input input{
margin: 2vh;
}
.form-submit-button{
    display: flex;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      margin-top: 2vh;
      margin-bottom: 2vh;
      width: 30vh;
      height: 5vh;
      color: white;
      background-color: #3A54AA;
      border-radius: 20px;
      box-shadow: 0 0 4px grey;
      text-decoration: none;
      border: none;
      text-align: center;
      align-items: center;
      cursor: pointer;
}
.form-cancel-button{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline: auto;
  margin-bottom: 2vh;
  font-size: 2vh;
  border: none;
  color: #3a54aa;
  border-radius: 20px;
  text-decoration: underline;
  cursor: pointer;
}